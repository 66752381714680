import { useCanvasElementById } from "frontend/subscriptions";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Group, Rect } from "react-konva";
import { Html } from "react-konva-utils";
import consts from "shared/consts";
import {
  CanvasElement as CanvasElementModel,
  Point,
  Shape,
  StickyNote,
  TextBlock,
  File,
  Connector,
  Drawing,
  TextAlignment,
  TextEnabledElement,
  TaskCard,
  Frame,
} from "shared/datamodel/schemas";
import ShapeCanvasElement from "./elements/shape-element";
import StickyNoteCanvasElement, { StickyNoteMarginY } from "./elements/sticky-note-element";
import { TextBlockCanvasElement } from "./elements/text-block-element";
import { SyncService } from "frontend/services/syncService";
import { RW } from "shared/datamodel/replicache-wrapper/mutators";
import { ConnectorCanvasElement } from "./elements/connector-canvas-element";
import DrawingCanvasElement from "./elements/drawing-element";
import {
  textEnabledForElementType,
  contentAreaRectForElement,
  linkBadgePosition,
} from "./elements/canvas-elements-utils";
import ElementTextComponent from "./elements/element-text-component";
import FileCanvasElement from "./elements/file-element";
import TaskCardElement from "./elements/task-card-element";
import FrameCanvasElement from "./elements/frame-element";
import FrameTitleCanvasElement from "./elements/frame-title-element";
import { useAtomValue, useSetAtom } from "jotai";
import {
  layerRefAtom,
  transformerRefAtom,
  isThumbnailExportModeAtom,
  isExportingAtom,
  editingElementIdAtom,
} from "state-atoms";
import Modal from "frontend/modal/modal";
import EditElementLinkModal from "frontend/modals/edit-element-link-modal";
import { CanvasElementLinkBadge } from "./canvas-element-link-badge";
import { TransformHooks } from "frontend/hooks/use-transform-hooks";
import { SelectedElementOutline, SelectedElementOutlineWidth } from "./frontend-consts";
import { getBoundingBox } from "frontend/utils/node-utils";
import { MindmapNodeElement } from "shared/datamodel/schemas/mindmap";
import MindmapCanvasElement from "./mindmap-element";
import { IntegrationCanvasElementFreeStanding } from "./elements/integration-element";
import { IntegrationItem } from "shared/datamodel/schemas/integration-item";
import { MindmapOrgChartNodeElement } from "shared/datamodel/schemas/mindmap-org-chart";
import MindmapOrgChartCanvasElement from "./elements/org-chart/mindmap-org-chart-element";
import { OrgChartElement } from "shared/datamodel/schemas/org-chart";
import OrgChartCanvasElement from "./elements/org-chart/standalone/orgchart-element";
import { CardStack } from "shared/datamodel/schemas/card-stack";
import { CardStackElement } from "./elements/card-stack/card-stack-element";
import LiveIntegrationCardStack from "./elements/card-stack/live-card-stack";
import { LiveIntegrationElement } from "shared/datamodel/schemas/live-integration";
import TimelineCanvasElement from "./elements/timeline/timeline-element";
import { LiveTimelineElement, StaticTimelineElement, TimelineElement } from "shared/datamodel/schemas/timeline";
import LiveTimelineCanvasElement from "./elements/timeline/live-timeline-element";
import TableElement from "./elements/table/table-element";
import { TypeTableElement } from "shared/datamodel/schemas/table";
import { putAttachedConnectorDragState } from "shared/datamodel/canvas-element";

export default function CanvasElement({
  syncService,
  elementData,
  allElementsData,
  uniqueId,
  isSelected,
  drawOutlineAroundElements,
  isEditing,
  isEditingLink,
  isFrameHighlighted,
  onResize,
  onChangeElement,
  patchCanvasElement,
  patchAnything,
  onElementsMutationEnded,
  isSelectable,
  metaData,
  isThumbnail = false,
  isReadOnly,
  isInContainer = false,
}: {
  syncService?: SyncService<RW>;
  uniqueId: string;
  isSelected: boolean;
  drawOutlineAroundElements: boolean;
  isEditing: boolean;
  isEditingLink: boolean;
  isFrameHighlighted: boolean;
  onResize: (id: string, position: Point, scaleX: number, scaleY: number, rotation: number) => void;
  onChangeElement: (
    id: string,
    props: Map<string, any>,
    previousProps: Map<string, any>,
    addUndo: boolean,
    updateSubMenuData?: any
  ) => void;
  patchCanvasElement: (args: { id: string; patch: any; inversePatch?: any; details?: any }) => void;
  patchAnything: (args: { id: string; patch: any; inversePatch?: any; details?: any }) => void;
  onElementsMutationEnded: (info: Map<string, any>, undoInfo: Map<string, any>, subMenuData?: any) => void;
  isSelectable: boolean;
  elementData?: any; //Used to inject the element's json instead of getting it from reflect. Currently used to preview templates in the mini stage.
  allElementsData?: any; //used to inject the entire elements json instead of getting it from reflect. Currently used to preview templates in the mini stage.
  metaData?: any; //Used to inject the metadata json instead of getting it from reflect. Currently used to preview templates in the mini stage.
  isThumbnail?: boolean;
  isReadOnly: boolean;
  isInContainer?: boolean;
}) {
  const layerRef = useAtomValue(layerRefAtom);
  const transformerRef = useAtomValue(transformerRefAtom);
  const isThumbnailExportMode = useAtomValue(isThumbnailExportModeAtom);
  const isExportingCanvas = useAtomValue(isExportingAtom);
  const setEditingElementId = useSetAtom(editingElementIdAtom);

  const [elementType, elementId] = uniqueId.split("-");
  const element = syncService ? useCanvasElementById(syncService.getReplicache()!, uniqueId) : elementData;

  const ref = useRef<any>(null);

  const mutationHooks = useMemo(() => new TransformHooks(uniqueId, onResize), [uniqueId]);

  let changeElement = useCallback(
    (
      props: any,
      undoConfig: { shouldAdd: boolean; previousProps?: any },
      updateSubMenuData?: any, //take the last change and make it the default when selecting this element again from the menu (e.g. make the default color the last color selected)
      elementId?: string
    ) => {
      const previousMapProps = new Map<string, any>(Object.entries(undoConfig.previousProps || element || {}));

      const isFixedHeight = elementType === consts.CANVAS_ELEMENTS.SHAPE;
      if (isFixedHeight) {
        delete props.height;
      }

      const mapProps = new Map<string, any>(Object.entries(props));

      if (!mutationHooks.isMutating && elementType == consts.CANVAS_ELEMENTS.STICKY_NOTE) {
        setTimeout(() => transformerRef && transformerRef.current && transformerRef.current?.forceUpdate(), 5);
      }
      onChangeElement(elementId ?? uniqueId, mapProps, previousMapProps, undoConfig.shouldAdd, updateSubMenuData);
    },
    [onChangeElement, element, uniqueId, elementType, mutationHooks.isMutating]
  );

  let changeAnyElement = useCallback(
    (id: string, newProps: any, previousProps?: any) => {
      const mapProps = new Map<string, any>(Object.entries(newProps));
      const previousMapProps = new Map(Object.entries(previousProps ?? {}));
      onChangeElement(id, mapProps, previousMapProps, Boolean(previousProps));
    },
    [onChangeElement]
  );

  useEffect(() => {
    // text blocks control their own cursor - this is such as hack
    if (elementType != consts.CANVAS_ELEMENTS.TEXT_BLOCK && elementType != consts.CANVAS_ELEMENTS.CONNECTOR) {
      if (ref.current) {
        ref.current.getStage().container().style.cursor = isEditing ? "text" : "inherit";
      }
    }
  }, [isEditing]);

  function renderTextEditor(element: CanvasElementModel) {
    if (isThumbnailExportMode || !textEnabledForElementType(elementType)) {
      return null;
    }
    const contentArea = contentAreaRectForElement(element as TextEnabledElement, elementType);
    if (contentArea.width == 0 || contentArea.height == 0) {
      return null;
    }
    let align: TextAlignment = (element as TextEnabledElement)?.align ?? consts.DEFAULTS.TEXT_ALIGN;

    let onHeightChange = (height: number) => {
      height += StickyNoteMarginY;
      let minimumHeight = (element as any).isWide ? (element as any).width / 2 : (element as any).width;
      if (elementType == consts.CANVAS_ELEMENTS.STICKY_NOTE && (element as any).isWide) {
        //backward compatability for old and new sticky notes sizes
        if ((element as any).width === consts.DEFAULTS.STICKY_NOTE_HEIGHT * 4) {
          minimumHeight = consts.DEFAULTS.STICKY_NOTE_HEIGHT * 2;
        } else {
          minimumHeight = consts.DEFAULTS.STICKY_NOTE_HEIGHT;
        }
      }
      if (elementType === consts.CANVAS_ELEMENTS.STICKY_NOTE) {
        height = Math.max(minimumHeight, height);
      } else if ((element as any).width && height < (element as any).width) {
        height = (element as any).width;
      }
      changeElement({ height }, { shouldAdd: false }, undefined, uniqueId);
    };

    let scaleX = elementType === consts.CANVAS_ELEMENTS.SHAPE ? 1 / (element.scaleX || 1) : 1;
    let scaleY = elementType === consts.CANVAS_ELEMENTS.SHAPE ? 1 / (element.scaleY || 1) : 1;

    // FIX for bad canvas: we saw a canvas with negative scale, which caused negative radius and an exception
    // this is a fix to correct this issue if it ever happens again.
    // It happened just once, we don't know why
    if ((element.scaleX && element.scaleX < 0) || (element.scaleY && element.scaleY < 0)) {
      onResize(
        uniqueId,
        { x: element.x, y: element.y },
        Math.abs(element.scaleX ?? 1),
        Math.abs(element.scaleY ?? 1),
        (element as any).rotate
      );
    }

    function getElementVerticalAlign(): "top" | "middle" | "bottom" {
      switch (elementType) {
        case consts.CANVAS_ELEMENTS.SHAPE:
          const shape = element as Shape;
          if (shape.type !== consts.SHAPES.TRIANGLE) {
            return "middle";
          }
          return "top";
        case consts.CANVAS_ELEMENTS.MINDMAP:
          return "middle";
        case consts.CANVAS_ELEMENTS.MINDMAP_ORG_CHART:
          return "middle";
        default:
          return "top";
      }
    }

    function getElementPlaceholder() {
      switch (elementType) {
        case consts.CANVAS_ELEMENTS.TEXT_BLOCK:
          return consts.DEFAULTS.TEXT;
        default:
          return "";
      }
    }

    return (
      <ElementTextComponent
        id={uniqueId}
        element={element as TextEnabledElement}
        onChangeElement={changeElement}
        contentArea={contentArea}
        onTextHeightChange={elementType == consts.CANVAS_ELEMENTS.STICKY_NOTE ? onHeightChange : undefined}
        isEditing={isEditing}
        onStopEditing={() => {
          if (isEditing) setEditingElementId(null);
        }}
        align={align}
        verticalAlign={getElementVerticalAlign()}
        placeholder={getElementPlaceholder()}
        layerRef={layerRef}
        scaleX={scaleX}
        scaleY={scaleY}
        isWide={(element as any).isWide ?? false}
      />
    );
  }

  function changeElementLink(element: CanvasElementModel, newLink?: string) {
    changeElement({ link: newLink }, { shouldAdd: true, previousProps: { link: element.link } }, undefined, uniqueId);
  }

  function renderElement(element: CanvasElementModel) {
    switch (elementType) {
      case consts.CANVAS_ELEMENTS.SHAPE:
        return (
          <ShapeCanvasElement
            id={uniqueId}
            element={element as Shape}
            onShapeTypeChanged={() => transformerRef.current?.forceUpdate()}
            isFrameHighlighted={isFrameHighlighted}
          />
        );
      case consts.CANVAS_ELEMENTS.TEXT_BLOCK:
        return null; // should never get here
      case consts.CANVAS_ELEMENTS.DRAWING:
        return <DrawingCanvasElement element={element as Drawing} />;
      case consts.CANVAS_ELEMENTS.CONNECTOR:
        return null; // should never get here
      case consts.CANVAS_ELEMENTS.STICKY_NOTE:
        return null; // should never get here
      case consts.CANVAS_ELEMENTS.FILE:
        return (
          <FileCanvasElement
            id={uniqueId}
            element={element as File}
            metadata={metaData}
            rep={syncService?.getReplicache()!}
          />
        );
      case consts.CANVAS_ELEMENTS.TASK_CARD:
        return (
          <TaskCardElement
            syncService={syncService}
            id={uniqueId}
            element={element as TaskCard}
            onChangeElement={changeElement}
            onHeightChange={(height: any) => {
              changeElement({ height }, { shouldAdd: false });
              transformerRef && transformerRef.current && transformerRef.current.forceUpdate();
            }}
            isReadOnly={isReadOnly}
          />
        );
      case consts.CANVAS_ELEMENTS.FRAME:
        return (
          <FrameCanvasElement
            id={uniqueId}
            element={element as Frame}
            onChangeElement={changeElement}
            disableShadow={true}
            isFrameHighlighted={isFrameHighlighted}
            isThumbnail={isThumbnail}
          />
        );
      case consts.CANVAS_ELEMENTS.MINDMAP:
        return null; // should never get here
      case consts.CANVAS_ELEMENTS.MINDMAP_ORG_CHART:
        return null; // should never get here
      case consts.CANVAS_ELEMENTS.INTEGRATION:
        console.warn("should never get here");
        return null;
      case consts.CANVAS_ELEMENTS.TIMELINE:
        return null;
      default:
        return null;
    }
  }

  function renderFrameTitle() {
    if (isThumbnailExportMode || elementType !== consts.CANVAS_ELEMENTS.FRAME) {
      return null;
    }

    return <FrameTitleCanvasElement id={uniqueId} element={element as Frame} onChangeElement={changeElement} />;
  }

  function renderLink(element: CanvasElementModel) {
    if (!element?.link) {
      return null;
    }

    const { x, y } = linkBadgePosition(element, elementType);

    return (
      <CanvasElementLinkBadge
        url={element.link}
        scaleX={element.scaleX ?? 1}
        scaleY={element.scaleY ?? 1}
        x={x}
        y={y}
      />
    );
  }

  if (!element || element.hidden) {
    return null;
  }

  const hideOnExport =
    !isSelectable || (elementType === consts.CANVAS_ELEMENTS.FRAME && (element as Frame).visible === false);

  if (hideOnExport && isExportingCanvas) {
    return null;
  }

  if (!isInContainer && element.containerId) {
    return null;
  }

  if (elementType == consts.CANVAS_ELEMENTS.STICKY_NOTE) {
    return (
      <StickyNoteCanvasElement
        id={uniqueId}
        element={element as StickyNote}
        mutation={mutationHooks}
        isSelected={isSelected && !drawOutlineAroundElements}
        isSelectable={isSelectable}
        isEditing={isEditing}
        changeElement={changeElement}
        isFrameHighlighted={isFrameHighlighted}
        isEditingLink={isEditingLink}
        renderTextEditor={renderTextEditor}
        renderLink={renderLink}
        changeElementLink={changeElementLink}
        isInContainer={isInContainer}
      />
    );
  }
  if (elementType == consts.CANVAS_ELEMENTS.TABLE) {
    return (
      <TableElement
        id={uniqueId}
        element={element as TypeTableElement}
        onResize={onResize}
        onChangeElement={onChangeElement}
        isSelected={isSelected}
        isSelectable={isSelectable}
        changeElement={changeElement}
        patchElement={patchCanvasElement}
        patchAnything={patchAnything}
        onMutation={(patches: any[]) => patches.forEach(patchAnything)} // TODO: this should be a done in a single transaction, for consistency and for undo/redo
      />
    );
  }

  if (elementType == consts.CANVAS_ELEMENTS.CONNECTOR) {
    // Hack: we had a bug that created connector elements with no data.
    // This is a temporary fix to prevent the app from crashing
    // The root cause will be fixed in an another PR, and I have no idea how to fix the data
    if (element.type != "connector" || !element.connectedShapes) return null;
    return (
      <ConnectorCanvasElement
        uniqueId={uniqueId}
        element={element as Connector}
        mutation={mutationHooks}
        isSelected={isSelected && !drawOutlineAroundElements}
        isSelectable={isSelectable}
        isEditing={isEditing}
        layer={layerRef?.current}
        onElementsMutationEnded={onElementsMutationEnded}
        changeElement={changeElement}
        changeAnyElement={changeAnyElement}
        allElements={allElementsData}
      />
    );
  }

  if (elementType == consts.CANVAS_ELEMENTS.TEXT_BLOCK) {
    return isThumbnailExportMode ? null : (
      <TextBlockCanvasElement
        id={uniqueId}
        element={element as TextBlock}
        isSelected={isSelected}
        isSelectable={isSelectable}
        drawOutlineAroundElements={drawOutlineAroundElements}
        isEditing={isEditing}
        isEditingLink={isEditingLink}
        onResize={onResize}
        changeElement={changeElement}
      />
    );
  }

  if (elementType === consts.CANVAS_ELEMENTS.MINDMAP) {
    const node = element as MindmapNodeElement;
    if (node.parentId || uniqueId !== node.rootId) {
      return null;
    }
    return (
      <MindmapCanvasElement id={uniqueId} element={node} changeElement={changeElement} allElements={allElementsData} />
    );
  }

  if (elementType === consts.CANVAS_ELEMENTS.MINDMAP_ORG_CHART) {
    const node = element as MindmapOrgChartNodeElement;
    if (node.parentId || node.selfId !== node.rootId) {
      return null;
    }
    return <MindmapOrgChartCanvasElement id={uniqueId} element={node} changeElement={changeElement} />;
  }

  if (elementType == consts.CANVAS_ELEMENTS.ORG_CHART) {
    return (
      <OrgChartCanvasElement
        uniqueId={uniqueId}
        element={element as OrgChartElement}
        patchCanvasElement={patchCanvasElement}
        editable={!element.lock}
      />
    );
  }

  if (elementType === consts.CANVAS_ELEMENTS.CARD_STACK) {
    return (
      <CardStackElement
        syncService={syncService}
        id={uniqueId}
        element={element as CardStack}
        changeElement={changeElement}
        onChangeElement={onChangeElement}
        isEditing={isEditing}
        onStopEditing={() => {
          if (isEditing) setEditingElementId(null);
        }}
        onResize={onResize}
      />
    );
  }

  if (elementType === consts.CANVAS_ELEMENTS.LIVE_INTEGRATION) {
    return (
      <LiveIntegrationCardStack
        id={uniqueId}
        element={element as LiveIntegrationElement}
        changeElement={changeElement}
        onChangeElement={onChangeElement}
        isEditing={isEditing}
        onStopEditing={() => {
          if (isEditing) setEditingElementId(null);
        }}
        onResize={onResize}
      />
    );
  }

  if (elementType == consts.CANVAS_ELEMENTS.INTEGRATION) {
    if (isInContainer || !element.containerId) {
      return (
        <IntegrationCanvasElementFreeStanding
          id={uniqueId}
          element={element as IntegrationItem}
          syncService={syncService}
          isEditable={!isReadOnly}
          isEditingLink={isEditingLink}
          changeElement={changeElement}
          onResize={onResize}
        />
      );
    } else {
      return null;
    }
  }

  if (elementType === consts.CANVAS_ELEMENTS.TIMELINE) {
    if (element.integrationId) {
      return (
        <LiveTimelineCanvasElement
          id={uniqueId}
          syncService={syncService}
          element={element as LiveTimelineElement}
          onResize={onResize}
          changeElement={changeElement}
          onChangeItems={onChangeElement}
          isSelected={isSelected}
          isSelectable={isSelectable}
          isReadOnly={isReadOnly}
        />
      );
    }
    return (
      <TimelineCanvasElement
        id={uniqueId}
        syncService={syncService}
        element={element as StaticTimelineElement}
        allElements={allElementsData}
        onResize={onResize}
        changeElement={changeElement}
        onChangeItems={onChangeElement}
        isSelected={isSelected}
        isSelectable={isSelectable}
        isReadOnly={isReadOnly}
      />
    );
  }

  // axis-aligned bounding box for the element
  const AABB = drawOutlineAroundElements && !element.groupId ? getBoundingBox(elementType, element) : null;
  const rotation = (element as any).rotate;
  const render = (
    <>
      {renderFrameTitle()}
      <Group
        ref={ref}
        id={uniqueId}
        name={uniqueId}
        type={elementType}
        x={element.x}
        y={element.y}
        scaleX={element.scaleX}
        scaleY={element.scaleY}
        rotation={rotation}
        {...mutationHooks.getCallbacks()}
        isCanvasElement={true}
        isSelectable={isSelectable}
        isConnectable={
          elementType != consts.CANVAS_ELEMENTS.CONNECTOR &&
          elementType != consts.CANVAS_ELEMENTS.COMMENT &&
          elementType != consts.CANVAS_ELEMENTS.MINDMAP &&
          elementType != consts.CANVAS_ELEMENTS.MINDMAP_ORG_CHART &&
          elementType != consts.CANVAS_ELEMENTS.ORG_CHART
        }
        isTaskConvertible={
          !!element.text &&
          (elementType === consts.CANVAS_ELEMENTS.TEXT_BLOCK ||
            elementType === consts.CANVAS_ELEMENTS.STICKY_NOTE ||
            elementType === consts.CANVAS_ELEMENTS.SHAPE)
        }
        isDraggable={true}
        isConnector={elementType === consts.CANVAS_ELEMENTS.CONNECTOR}
        isFrame={elementType === consts.CANVAS_ELEMENTS.FRAME}
        attachedConnectors={element.attachedConnectors}
        element={element}
        onMouseEnter={(e) => {
          if (isSelected && isEditing) {
            e.target.getStage()!.container().style.cursor = "text";
          }
        }}
        onMouseLeave={(e) => {
          if (isSelected && isEditing) {
            e.target.getStage()!.container().style.cursor = "default";
          }
        }}
        onMouseDown={(e) => {
          // When this element is in text-edit mode, clicking on the shape
          // will unfocus the textarea and make the cursor disappear
          // But the shape is still marked as the edited element!
          // This gives a very bad user-experience, so we cancel the click event to
          // stop the focus loss
          if (isSelected && isEditing) {
            e.evt.preventDefault();
          }
        }}
      >
        {renderElement(element)}
        {renderTextEditor(element)}
        {renderLink(element)}
      </Group>
      {AABB && !isExportingCanvas && (
        <Rect
          {...AABB}
          strokeWidth={SelectedElementOutlineWidth}
          stroke={SelectedElementOutline}
          strokeScaleEnabled={false}
        />
      )}
      {isEditingLink && (
        <Html>
          <Modal dimBackground={true}>
            <EditElementLinkModal element={element} onChangeLink={changeElementLink} />
          </Modal>
        </Html>
      )}
    </>
  );
  return render;
  //the portal prevents the connector-text editing feature. why???
  // it can also create unexpected behviour for users
  // return (isEditing && elementType!=consts.CANVAS_ELEMENTS.CONNECTOR) ? <Portal selector={".Editing"} enabled={true}>{render}</Portal> : render;
}
