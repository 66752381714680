import { IntegrationTemplateLoader } from "frontend/canvas-designer-new/integration-template-loader/integration-template-loader";
import React, { CSSProperties } from "react";
import { Html } from "react-konva-utils";

type KonvaIntegrationLoaderProps = {
  x: number;
  y: number;
  rectLength?: number;
  style?: Pick<CSSProperties, "minWidth" | "minHeight">;
};

export const KonvaIntegrationLoader = ({
  x,
  y,
  rectLength = 400,
  style = {
    minWidth: 400,
    minHeight: 300,
  },
}: KonvaIntegrationLoaderProps) => {
  return (
    <Html
      groupProps={{
        x: x - rectLength / 2,
        y: y,
      }}
    >
      <div style={style}>
        <IntegrationTemplateLoader />
      </div>
    </Html>
  );
};
