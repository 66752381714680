import { getReadSignedUrl, getSignedUrl } from "frontend/api";
import React, { useEffect, useRef, useState } from "react";
import { File } from "shared/datamodel/schemas/file";
import { Image, Rect } from "react-konva";
import Konva from "konva";
import { Html } from "react-konva-utils";
import style from "./file-element.module.css";
import { useAtomValue } from "jotai";
import { documentIdAtom, isThumbnailExportModeAtom } from "state-atoms/stage-atoms";
import { FileMetadata, FileMetadataType } from "shared/datamodel/schemas/metadata";
import { M } from "shared/datamodel/mutators";
import { Reflect } from "@workcanvas/reflect/client";
import { useSubscribe } from "replicache-react";
import { canvasMetadataPrefix } from "shared/util/utils";
import { ReadTransaction } from "@workcanvas/reflect";

export type FilesMetadata = Record<string, FileMetadata>;

const fetchImage = async (url: string, crossOrigin: string | null = "anonymous") => {
  const img = document.createElement("img");
  crossOrigin && (img.crossOrigin = crossOrigin);
  let result = new Promise<HTMLImageElement>((resolve, reject) => {
    img.onload = () => resolve(img);
    img.onerror = reject;
  });
  img.src = url!;
  return result;
};

// TODO:
// remember expiry time for signed urls and ask them again when expired
// put the image on a canvas element, not image element

function FileCanvasElement({
  id,
  element,
  metadata,
  rep,
}: {
  id: string;
  element: File;
  metadata?: any;
  rep: Reflect<M>;
}) {
  const ref = useRef<Konva.Image>(null);
  let { documentId } = useAtomValue(documentIdAtom);
  const isThumbnailExportMode = useAtomValue(isThumbnailExportModeAtom);

  let { fileId, width = 300, height = 150 } = element;

  const fileMetadataEntry = metadata
    ? metadata[fileId!]
    : useSubscribe(
      rep,
      async (tx: ReadTransaction) => await tx.get(canvasMetadataPrefix + FileMetadataType + "-" + fileId),
      null,
      [fileId]
    );

  const [img, setImg] = useState<HTMLImageElement | null>(null);
  const [imgStatus, setImgStatus] = useState("loading");

  useEffect(() => {
    async function getFile(fileId: string | number) {
      let response: any;
      try {
        if (Number(fileId)) {
          response = await getSignedUrl({ action: "read", fileId: Number(fileId), documentId });
        } else if (fileMetadataEntry) {
          response = await getReadSignedUrl(fileMetadataEntry.url);
        } else {
          return;
        }
        const img = await fetchImage(response.url);
        setImg(img);
        setImgStatus("loaded");
      } catch (e) {
        console.error("Failed to fetch image", e);
        setImgStatus("failed");
      }
    }
    fileId && getFile(fileId);
  }, [fileId, fileMetadataEntry, documentId]);

  //cache the image ands scale down pixelRatio for perforamnce reasons
  if (ref.current && !ref.current.isCached()) {
    ref.current.cache({ pixelRatio: 0.7 });
  }

  function renderFilePlaceHolder() {
    return (
      <Html
        divProps={{
          style: {
            position: "absolute",
            top: 0,
            left: 0,
            opacity: 0.6,
            pointerEvents: "none",
          },
        }}
      >
        <div className={style.filePlaceHolder} style={{ width, height }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40%"
            height="40%"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid meet"
          >
            <text x="50%" y="50%" textAnchor="middle" alignmentBaseline="middle">
              Downloading file...
            </text>
          </svg>
        </div>
      </Html>
    );
  }

  function renderThumbnail() {
    const image = img as HTMLImageElement;
    return <Rect fill="#000000" opacity={0.4} width={image.naturalWidth} height={image.naturalHeight} />;
  }

  switch (imgStatus) {
    case "loaded":
      return (
        <>
          <Image ref={ref} image={img as HTMLImageElement} visible={!isThumbnailExportMode} />
          {isThumbnailExportMode && renderThumbnail()}
        </>
      );
    case "loading":
      return renderFilePlaceHolder();
    default:
      return null;
  }
}

const MemoizedFileElement = React.memo(FileCanvasElement);
export default MemoizedFileElement;
