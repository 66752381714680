import React, { useEffect, useState } from "react";
import { Shape } from "react-konva";
import { getReadSignedUrl } from "frontend/api";
import { PictureRadius } from "./orgchart-element";
import { useAtomValue } from "jotai";
import { syncServiceAtom } from "state-atoms";
import { ReadTransaction } from "@workcanvas/reflect";

export function OrgchartPersonPhoto({
  x,
  y,
  photoUrl,
  onClick,
}: {
  x: number;
  y: number;
  photoUrl?: string;
  onClick?: () => void;
}) {
  const [img, setImg] = useState<any>(null);
  const syncService = useAtomValue(syncServiceAtom);
  // TODO: the read-signed-urls can be cached globally (per-canvas),
  // and refetched only if they expire
  useEffect(() => {
    if (photoUrl) {
      (photoUrl.includes("/")
        ? getReadSignedUrl(photoUrl)
        : syncService
          .getReplicache()!
          .query(async (tx: ReadTransaction) => tx.get("cMetadata-File-" + photoUrl))
          .then((value: any) => getReadSignedUrl(value.url))
      )
        .then(({ url }: { url: string }) => {
          let img = document.createElement("img");

          const onload = () => setImg({ status: "loaded", img });
          const onerror = () => setImg({ status: "error" });
          img.addEventListener("load", onload);
          img.addEventListener("error", onerror);
          img.crossOrigin = "anonymous";
          img.width = 40;
          img.height = 40;
          img.alt = "user photo";
          img.src = url;
        })
        .catch((err: any) => {
          setImg({ status: "error getting read-signed-url" });
          console.error("failed to load image: can't get read-signed-url", err);
        });
    }
  }, [photoUrl]);

  // remove the image on unload (can images be cached in a better way??)
  useEffect(() => {
    if (img && img.img) return () => img.img.remove();
  }, [img]);

  const mouseHandling = onClick
    ? {
      // I manually code the onClick logic to avoid drag-and-drop events triggering the click
      onMouseDown: (e: any) => (e.currentTarget.attrs.mousedown = { x: e.evt.offsetX, y: e.evt.offsetY }),
      onMouseUp: (e: any) => {
        if (e.currentTarget.attrs.mousedown) {
          const { x, y } = e.currentTarget.attrs.mousedown;
          if (Math.sqrt(x - e.evt.offsetX) ** 2 + Math.sqrt(y - e.evt.offsetY) ** 2 < 4) {
            onClick && onClick();
          }
        }
        e.currentTarget.attrs.mousedown = null;
      },
      onMouseEnter: (e: any) => (e.target.getStage()!.container().style.cursor = "pointer"),
      onMouseLeave: (e: any) => (e.target.getStage()!.container().style.cursor = "default"),
    }
    : undefined;

  return (
    <Shape
      x={x}
      y={y}
      fill="white"
      shadowEnabled={true}
      shadowOffsetX={0}
      shadowOffsetY={4}
      shadowColor="#6F7284"
      shadowOpacity={0.25}
      shadowBlur={4}
      {...mouseHandling}
      hitStrokeWidth={0}
      perfectDrawEnabled={false}
      listening={!!onClick}
      hitFunc={(context, shapeConfig) => {
        context.beginPath();
        context.arc(0, 0, PictureRadius + 2, 0, Math.PI * 2, false);
        context.fillStrokeShape(shapeConfig);
      }}
      sceneFunc={(context, shapeConfig) => {
        // white ring that will also create the shadow
        context.save();
        context.beginPath();
        context.arc(0, 0, PictureRadius + 2, 0, Math.PI * 2, false);
        context.fillStrokeShape(shapeConfig);
        // clip circle
        context.beginPath();
        context.arc(0, 0, PictureRadius, 0, Math.PI * 2, false);
        context.clip();
        context.closePath();

        if (img && img.status == "loaded") {
          context.drawImage(img.img, -PictureRadius, -PictureRadius, PictureRadius * 2, PictureRadius * 2);
        } else {
          // draw a generic avatar icon
          context._context.shadowColor = "transparent";
          context._context.fillStyle = "#F4F7FA";
          context._context.arc(0, 0, 100, 0, Math.PI * 2, false);
          context._context.fill();
          context._context.fillStyle = "#DADCE0";
          context._context.beginPath();
          context._context.arc(0, -3.5, 8, 0, Math.PI * 2, false);
          context._context.arc(0, 19, 13, 0, Math.PI * 2, false);
          context._context.fill();
        }
        context.restore();
      }}
    />
  );
}
