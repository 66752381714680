import AppLoader from "frontend/loader/app-loader";
import { useEffect, useState } from "react";
import styles from "./integration-template-loader.module.css";

const DEFAULT_SENTENCES = [
  "Syncing your monday board with workcanvas...",
  "Fetching the items...",
  "Placing everything in the right place...",
  "Finishing up...",
];

export function IntegrationTemplateLoader({ sentences = DEFAULT_SENTENCES }: { sentences?: string[] }) {
  const [currentSentence, setCurrentSentence] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSentence((currentSentence) => (currentSentence + 1) % sentences.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.lottiePlaceholder}></div>
      <AppLoader
        customStyle={{
          width: "150px",
          height: "150px",
        }}
        lottieSrc="/images/integrations-loader.json"
      />
      <div className={styles.sentence}>{sentences[currentSentence]}</div>
    </div>
  );
}
