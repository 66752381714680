import React, { useEffect, useRef } from "react";
import consts from "shared/consts";
import PolygonElement from "frontend/utils/PolygonElement";
import { Ellipse, Group, Rect, Shape } from "react-konva";
import { defaultShapeProperties, Shape as ShapeElement } from "shared/datamodel/schemas/shape";
import { ITraits, Trait } from "../elements-toolbar/elements-toolbar-types";
import { textEnabledTraits } from "./text-block-element";
import { replaceColorOpacity } from "frontend/utils/color-utils";
import { parseStrokeWidth } from "shared/util/utils";
import { calcDashProperties } from "frontend/utils/node-utils";
import { Degrees, toRadians } from "frontend/utils/transform";
import { rotate as pointRotate } from "frontend/utils/point-utils";
import ShapesData from "frontend/data/shapes/shapes-visuals";
import { getDefaultSizeForShape } from "frontend/utils/shape-utils";

// points => split by commas+spaces => map to numbers => map to scaled values
function pointsToNumbers(points: string) {
  return points
    .replace(/[\s,]+/g, " ")
    .split(" ")
    .map(Number);
}

export default function ShapeCanvasElement({
  id,
  element,
  onShapeTypeChanged,
  isFrameHighlighted,
}: {
  id: string;
  element: ShapeElement;
  onShapeTypeChanged: any;
  isFrameHighlighted: boolean;
}) {
  const {
    width,
    height,
    radius,
    fill,
    type,
    border,
    scaleX = 1,
    scaleY = 1,
    dash,
    strokeWidth,
    subtype,
  } = {
    ...defaultShapeProperties,
    ...element,
  };

  const sw = parseStrokeWidth(strokeWidth);

  const currentType = useRef<any>({ type, subtype });
  const shapeProps = {
    fill,
    stroke: border,
    strokeEnabled: border != "transparent",
    strokeWidth: sw,
    perfectDrawEnabled: false,
    shadowForStrokeEnabled: false,
    hitStrokeWidth: 0,
    ...calcDashProperties(sw, dash),
  };

  const polygonProps = {
    ...shapeProps,
    radius,
    scaleX,
    scaleY,
  };
  const rectProps = {
    ...shapeProps,
    width: width * scaleX,
    height: height * scaleY,
    scaleX: 1 / scaleX,
    scaleY: 1 / scaleY,
  };

  useEffect(() => {
    if (type !== currentType.current.type || subtype != currentType.current.subtype) {
      //call onShapeTypeChanged only when the type changes (this effect is called when type is loaded as well, causing a bug if this check is not here)
      currentType.current = { type, subtype };
      onShapeTypeChanged();
    }
  }, [type, subtype]);

  function renderShapeByType(extraProps: any = {}) {
    if (type == consts.CANVAS_ELEMENTS.SHAPE && subtype) {
      let data = subtype ? (ShapesData as any)[subtype] : undefined;
      let viewbox = data?.viewbox;
      if (!viewbox) return null;
      const [x, y, width, height] = viewbox;
      const center = { x: x + width / 2, y: y + height / 2 };
      const size = { width, height };
      return (
        <Group offset={center}>
          <Shape
            listening={false}
            {...shapeProps}
            // these 3 props ensure that getClientRect returns {0,0,0,0} so the
            // shape doesn't affect the parent's bounding box, and that way I can control the bounding-box
            // better for the transformer
            width={0}
            height={0}
            strokeWidth={0}
            sceneFunc={(context, shape) => {
              try {
                const c = context._context; // I want to use the native canvas context so I can use Path2d
                const withFill = shape.fillEnabled();
                const withStroke = shape.strokeEnabled();
                // I have to set all the parameters myself since I won't use Konva's fill/stroke functions
                c.lineWidth = sw;
                c.lineJoin = shape.lineJoin();
                c.lineCap = shape.lineCap();
                c.lineDashOffset = shape.dashOffset();
                c.setLineDash(shape.dashEnabled() ? shape.dash() : []);

                // I cancel the scaling and rotation.
                // 1. scale is cancelled because I don't want stroke to be scaled with the shape
                // 2. rotation is cancelled because in exporting to pdf, js-pdf doesn't handle arc commands with rotation well.
                const rotDegrees = element.rotate ?? 0;
                const rotRadians = (rotDegrees / 180) * Math.PI;
                c.scale(1 / scaleX, 1 / scaleY);
                c.rotate(-rotRadians);
                // Instead of the cancelled transform, I'll build a matrix with the correct transform, and apply it to the points
                const matrix = new DOMMatrix().rotate(rotDegrees).scale(scaleX, scaleY);

                for (const cmd of data.segments) {
                  c.beginPath();

                  let p: undefined | Path2D;

                  if (cmd.type == "path") {
                    let d = cmd.d;
                    if (typeof cmd.d == "function") {
                      d = cmd.d(scaleX, scaleY);
                    }
                    if ((c as any).isPdfContext) {
                      (c as any).drawSvgPath(d, matrix);
                    } else {
                      p = new Path2D();
                      p.addPath(new Path2D(d), matrix);
                    }
                  } else if (cmd.type == "polygon") {
                    let r = cmd.cornerRadius;
                    let points = cmd.points;
                    if (typeof points == "function") {
                      points = points(scaleX, scaleY);
                    }
                    // applying the transform we canceled to points
                    let numbers = pointsToNumbers(points);
                    points = [];
                    for (let i = 0; i < numbers.length; i += 2) {
                      let p = matrix.transformPoint({ x: numbers[i], y: numbers[i + 1] });
                      points.push(p.x, p.y);
                    }
                    //TODO: we just scaled the shape down so its lines can be shorted than the radius of corners
                    //solution should be radius=min(radius, min(...lines))

                    // start point is the middle of the first line, not in a corner.
                    let startX = (points[0] + points[2]) / 2;
                    let startY = (points[1] + points[3]) / 2;
                    c.moveTo(startX, startY);
                    for (let i = 2; i < points.length + 2; i += 2) {
                      let x1 = points[i % points.length];
                      let y1 = points[(i + 1) % points.length];
                      let x2 = points[(i + 2) % points.length];
                      let y2 = points[(i + 3) % points.length];
                      c.arcTo(x1, y1, x2, y2, r);
                    }
                    c.closePath();
                  }

                  if (withFill && cmd.fill) {
                    switch (cmd.fill) {
                      case "fill":
                        c.fillStyle = shape.fill();
                        break;
                      case "stroke":
                        c.fillStyle = shape.stroke();
                        break;
                      default:
                        c.fillStyle = cmd.fill;
                    }
                    p ? c.fill(p) : c.fill();
                  }
                  if (withStroke && cmd.stroke) {
                    switch (cmd.stroke) {
                      case "fill":
                        c.strokeStyle = shape.fill();
                        break;
                      case "stroke":
                        c.strokeStyle = shape.stroke();
                        break;
                      default:
                        c.strokeStyle = cmd.stroke;
                    }
                    p ? c.stroke(p) : c.stroke();
                  }
                }
              } catch (e) {
                console.error("shape sceneFunc error:", e);
              }
            }}
          />
          {/* a rectangle to cover the box, invisible on canvas,
                appears on Konva's hit canvas and also give size of the shape\
                for the transformer.
            */}
          <Rect
            x={center.x - size.width / 2}
            y={center.y - size.height / 2}
            scaleX={1 / scaleX} // already in the drawing
            scaleY={1 / scaleY}
            width={size.width * scaleX}
            height={size.height * scaleY}
            strokeEnabled={shapeProps.strokeEnabled}
            strokeWidth={shapeProps.strokeWidth}
            stroke="transparent"
            hitStrokeWidth={shapeProps.strokeWidth}
            fillEnabled={true}
            fill="transparent"
          />
        </Group>
      );
    }

    switch (type) {
      case consts.SHAPES.RECT:
        return <Rect {...rectProps} {...extraProps} />;
      case consts.SHAPES.RECT_ROUNDED:
        return <Rect cornerRadius={consts.DEFAULTS.ROUNDED_RECT_RADIUS} {...rectProps} {...extraProps} />;
      case consts.SHAPES.DIAMOND:
        return <PolygonElement sides={4} {...polygonProps} {...extraProps} />;
      case consts.SHAPES.CIRCLE:
        return (
          <Ellipse
            {...shapeProps}
            {...extraProps}
            radiusX={radius * scaleX}
            radiusY={radius * scaleY}
            scaleX={1 / scaleX}
            scaleY={1 / scaleY}
          />
        );
      case consts.SHAPES.TRIANGLE:
        return <PolygonElement sides={3} {...polygonProps} {...extraProps} />;
      case consts.SHAPES.HEXAGON:
        return <PolygonElement sides={6} {...polygonProps} {...extraProps} />;
      default:
        return <React.Fragment />;
    }
  }

  function renderHighlight() {
    return renderShapeByType({
      fill: "rgba(0, 0, 0, 0.2)",
    });
  }

  return (
    <>
      {renderShapeByType()}
      {isFrameHighlighted && renderHighlight()}
    </>
  );
}

export function shapeTraits(element: ShapeElement): ITraits {
  return {
    ...textEnabledTraits(element),
    shape: element.type,
    shapeStrokeColor: element.border,
    fillColor: element.fill,
    shapeStrokeWidth: element.strokeWidth ?? 4,
    dash: element.dash ?? 0,
  };
}

function isOldStyleShape(shape: string) {
  return shape != consts.CANVAS_ELEMENTS.SHAPE; // && Object.values(consts.SHAPES).includes(shape)
}

export function shapeValidateTrait(element: ShapeElement, trait: Trait, value: any) {
  if (trait == Trait.fillColor && typeof value == "number") {
    return replaceColorOpacity(element.fill, value);
  }
  if (trait == Trait.shapeStrokeColor && typeof value == "number") {
    return replaceColorOpacity(element.border, value);
  }
  if (trait == Trait.shape) {
    const fromOldShape = isOldStyleShape(element.type);
    const toTriangle = value == "triangle"; // triangles I want to draw with the old code, for now...
    if (fromOldShape && toTriangle) {
      let props = adjustShapeForTypeChange(element, value);
      return props;
    }

    if (fromOldShape && !toTriangle) {
      const isRect = element.type == "rect" || element.type == "rect-rounded";
      let [w, h] = isRect ? [element.width, element.height] : [element.radius * 2, element.radius * 2];
      w *= element.scaleX ?? 1;
      h *= element.scaleY ?? 1;
      const [cx, cy] = isRect ? [element.x + w / 2, element.y + h / 2] : [element.x, element.y];
      let props = {
        type: consts.CANVAS_ELEMENTS.SHAPE,
        subtype: value,
        x: cx,
        y: cy,
        width: 200,
        height: 200,
        radius: 100,
        scaleX: w / 200,
        scaleY: h / 200,
      };
      return props;
    }

    // For SVG shape transitions, to prevant deformation we scale
    // the element according to the default sizes of the new shape.
    const { width, height } = getDefaultSizeForShape(element.type, element.subtype);
    const { width: newWidth, height: newHeight } = getDefaultSizeForShape(element.type, value);
    const props = {
      type: consts.CANVAS_ELEMENTS.SHAPE,
      subtype: value,
      width: newWidth,
      height: newHeight,
      radius: 100,
      scaleX: element.scaleX * (width / newWidth),
      scaleY: element.scaleY * (height / newHeight),
    };
    return props;
  }
  return value;
}

//When changing type from e.g. rect to circle, the position should be adjusted accordingly since konva calculates the center of these shapes differently
// This is a bad way of doing this. Konva gives us the offset property which can fix this easily
function adjustShapeForTypeChange(shape: ShapeElement, toType: string) {
  const from = shape.type;
  const fromRect = from === "rect" || from === "rect-rounded";
  const toRect = toType === "rect" || toType === "rect-rounded";
  if (fromRect && !toRect) {
    const { x, y, width, height, scaleX = 1, scaleY = 1, rotate = 0 } = shape;
    // calculate the real width and height after scaling
    const w = scaleX * width!;
    const h = scaleY * height!;
    // calculate the offset of shape's center after its rotation
    let offset = { x: w / 2, y: h / 2 };
    let rad = toRadians(rotate as Degrees);
    pointRotate(offset, rad, offset);
    let radius = Math.min(w, h) / 2;
    return {
      x: x + offset.x,
      y: y + offset.y,
      width,
      height,
      radius,
      type: toType,
      subtype: "",
      scaleX: (0.5 * w) / radius,
      scaleY: (0.5 * h) / radius,
      rotate,
    };
  } else if (!fromRect && toRect) {
    const { x, y, radius, scaleX = 1, scaleY = 1, rotate = 0 } = shape;

    let ofsX = radius! * scaleX,
      ofsY = radius! * scaleY;
    let rad = (rotate * Math.PI) / 180;
    let rotatedOfsX = ofsX * Math.cos(rad) - ofsY * Math.sin(rad);
    let rotatedOfsY = ofsX * Math.sin(rad) + ofsY * Math.cos(rad);
    return {
      x: x - rotatedOfsX,
      y: y - rotatedOfsY,
      width: radius! * 2,
      height: radius! * 2,
      radius,
      type: toType,
      subtype: "",
      scaleX,
      scaleY,
      rotate,
    };
  }
  return { ...shape, type: toType };
}
