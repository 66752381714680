import { fontPropertiesToString, konvaTextDecoration } from "shared/util/utils";
import { TableTextShema } from "shared/datamodel/schemas/table";
import { CSSProperties, useMemo, useRef } from "react";
import { useSubscribe } from "replicache-react";
import { customAlphabet } from "nanoid";
import { clamp } from "frontend/utils/math-utils";
import { deepEqual } from "frontend/utils/fn-utils";
import { ReadTransaction } from "@workcanvas/reflect";
import { Reflect } from "@workcanvas/reflect/client";
import { M } from "shared/datamodel/mutators";
import { values } from "rambda";

export const cellPadding = 10;
// TODO: duplicated from table.ts
export const cellNanoid = customAlphabet("0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ", 10);

export const textStyleKonva = (cell: TableTextShema, defaultStyle: TableTextShema) => ({
  fontFamily: cell?.font || defaultStyle.font,
  font: cell?.font || defaultStyle.font,
  fontSize: cell?.fontSize || defaultStyle.fontSize,
  fill: cell?.textColor || defaultStyle.textColor,
  align: cell?.align || defaultStyle.align,
  verticalAlign: "top", //cell?.valign || defaultStyle.valign,
  textDecoration: konvaTextDecoration(cell?.fontProps || defaultStyle.fontProps),
  fontStyle: fontPropertiesToString(cell?.fontProps || defaultStyle.fontProps),
  ellipsis: false,
  wrap: "word",
  perfectDrawEnabled: false,
});

export const cssTextStyle = (cell: TableTextShema, defaultStyle: TableTextShema) => {
  const fontSize = cell?.fontSize || defaultStyle.fontSize;
  const fontFamily = cell?.font || defaultStyle.font;
  const props = fontPropertiesToString(cell?.fontProps ?? defaultStyle?.fontProps);
  return {
    font: `${props} ${fontSize}px ${fontFamily}`,
    textDecoration: konvaTextDecoration(cell?.fontProps || defaultStyle.fontProps),
    color: cell?.textColor || defaultStyle.textColor,
    textAlign: cell?.align || defaultStyle.align,
  } as CSSProperties;
};

export const cellIdPrefix = "cells";
export const cellId = (row: string, col: string) => `${col}-${row}`; // TODO: reverse order of arguments
export const prefixForCellsOfTable = (tableId: string) => `${cellIdPrefix}/${tableId}-`;
export const cellIdForReflect = (tableId: string, row: string, col: string) =>
  prefixForCellsOfTable(tableId) + cellId(row, col);

//TODO: move to another file
//#region tasks
export const queueWork = (cb: () => void) => {
  window.requestAnimationFrame(() => setTimeout(cb));
};

function yieldToMain() {
  return new Promise((resolve) => {
    setTimeout(resolve, 0);
  });
}

//see https://web.dev/articles/optimize-long-tasks#yield_only_when_necessary
async function runTasksGracefully(tasks: (() => void)[]) {
  const wait = (navigator as any).scheduler?.yield ?? yieldToMain;
  // Loop over the tasks:
  let task;
  while ((task = tasks.shift())) {
    // Run the task:
    task();

    // Yield to the main thread:
    await wait();
  }
  // TODO: try navigator.scheduler.postTask
  // const {signal} = new TaskController({priority: 'user-visible'});
  // await navigator.scheduler.postTask(callback, {signal})
}

function queueTasks(tasks: (() => void)[]) {
  queueWork(() => runTasksGracefully(tasks));
}
//#endregion

export interface CellCoordinate {
  row: string;
  col: string;
}

// TODO: unite this and the next function to 1 hook
export function useSubscribeCellsData(rep: Reflect<M> | undefined, id: string, allElements?: Record<string, any>) {
  if (allElements) {
    return allElements; //allElements should have the cells objects, so we can just return it
  }
  return useSubscribe(
    rep,
    async (tx: ReadTransaction) => {
      let prefix = prefixForCellsOfTable(id);
      let cells: Record<string, any> = {};
      for await (const [key, value] of tx.scan({ prefix }).entries()) {
        cells[key.substring(prefix.length)] = value;
      }
      return cells;
    },
    {}
  );
}

export function useSubscribeContainedElements(rep: Reflect<M> | undefined, cells: Record<string, any>, allElements?: Record<string, any>) {
  const queryRef = useRef(cells);
  const memoizedCells = deepEqual(queryRef.current, cells) ? queryRef.current : (queryRef.current = cells);
  if (allElements) {
    return useMemo(() => {
      let result: Record<string, any[]> = {};
      for (const [cellid, value] of Object.entries(memoizedCells)) {
        if (value.containedIds?.length) {
          result[cellid] = value.containedIds.map((id: string) => allElements["cElement-" + id]);
        }
      };
      return result;
    },
      [memoizedCells]);
  }
  return useSubscribe(
    rep,
    async (tx: ReadTransaction) => {
      let contents: Record<string, any> = {};
      let promises: Promise<any>[] = [];
      const query = Object.entries(cells).filter(([key, value]) => value.containedIds?.length);
      for (const [cellid, value] of query) {
        const ids = (value as any).containedIds;
        contents[cellid] = [];
        promises = promises.concat(
          ids.map((id: string) => tx.get("cElement-" + id).then((v: any) => contents[cellid].push([id, v])))
        );
      }
      await Promise.all(promises);
      return contents;
    },
    {} as Record<string, any[]>,
    [memoizedCells]
  );
}

interface Point {
  x: number;
  y: number;
}

const PointZero = { x: 0, y: 0 };

class RectSelection {
  private start = PointZero;
  private end = PointZero;

  reset(p: Point = PointZero) {
    this.start = { ...p };
    this.end = { ...p };
  }

  clampToBounds(max: Point) {
    this.end.x = Math.min(this.end.x, max.x);
    this.end.y = Math.min(this.end.y, max.y);
    this.start.x = Math.min(this.start.x, max.x);
    this.start.y = Math.min(this.start.y, max.y);
  }

  extendDelta(dx: number, dy: number, maxX: number, maxY: number) {
    this.end.x += dx;
    this.end.y += dy;
    this.end.x = clamp(this.end.x, 0, maxX - 1);
    this.end.y = clamp(this.end.y, 0, maxY - 1);
  }

  extendAbs(x: number, y: number) {
    this.end.x = x;
    this.end.y = y;
  }

  *[Symbol.iterator](): IterableIterator<Point> {
    const startX = Math.min(this.start.x, this.end.x);
    const endX = Math.max(this.start.x, this.end.x);
    const startY = Math.min(this.start.y, this.end.y);
    const endY = Math.max(this.start.y, this.end.y);
    for (let x = startX; x <= endX; x++) {
      for (let y = startY; y <= endY; y++) {
        yield { x, y };
      }
    }
  }
}

class SetSelection {
  // coords: [p1_x, p1_y, p2_x, p2_y, ...]
  // this way I don't allocate a new object for each point
  // another option for efficient storage: turn point into a number: x * N + y for very large N
  private coords: number[] = [];

  reset() {
    this.coords.length = 0;
  }

  clampToBounds(p: Point) {
    for (let i = 0; i < this.coords.length; i += 2) {
      let x = this.coords[i];
      let y = this.coords[i + 1];
      if (x >= p.x || y >= p.y) {
        this.coords.splice(i, 2);
        i -= 2;
      }
    }
  }

  toggle(p: Point) {
    let index = -1;
    for (let i = 0; i < this.coords.length; i += 2) {
      let x = this.coords[i];
      let y = this.coords[i + 1];
      if (x === p.x && y === p.y) {
        index = i;
        break;
      }
    }
    if (index != -1) {
      this.coords.splice(index, 2);
    } else {
      this.coords.push(p.x, p.y);
    }
  }

  *[Symbol.iterator](): IterableIterator<Point> {
    for (let i = 0; i < this.coords.length; i += 2) {
      yield { x: this.coords[i], y: this.coords[i + 1] };
    }
  }
}

export class TableSelection {
  set: SetSelection | null = null;
  rect: RectSelection | null = null;

  resizeTable(p: Point) {
    this.rect?.clampToBounds(p);
    this.set?.clampToBounds(p);
  }

  reset(start: Point) {
    this.set = null;
    this.rect ||= new RectSelection();
    this.rect.reset(start);
  }
  ensureRect(start: Point) {
    if (this.rect == null) {
      this.set = null;
      this.rect = new RectSelection();
      this.rect.reset(start);
    }
  }

  extendDelta(dx: number, dy: number, maxX: number, maxY: number) {
    this.rect?.extendDelta(dx, dy, maxX, maxY);
  }

  extendAbs(x: number, y: number) {
    this.rect?.extendAbs(x, y);
  }

  toggle(p: Point) {
    if (this.rect != null) {
      // convert the current rect-selection to a set-selection
      this.set = new SetSelection();
      for (const { x, y } of this.rect) this.set.toggle({ x, y });
      this.set.toggle(p);
      this.rect = null;
    } else {
      this.set ||= new SetSelection();
      this.set.toggle(p);
    }
  }

  *[Symbol.iterator](): IterableIterator<Point> {
    if (this.rect != null) {
      yield* this.rect[Symbol.iterator]();
    } else if (this.set != null) {
      yield* this.set[Symbol.iterator]();
    }
  }
}
