import {
  DRAW_PROPS_ID,
  OrgChartElement,
  OrgChartOrientation,
  OrgChartViewType,
  PHOTO_ID,
} from "shared/datamodel/schemas/org-chart";
import { produceWithPatches } from "immer";
import consts from "shared/consts";
import { customAlphabet } from "nanoid";
import { any, pick, keys, fromPairs, map, pipe, reject, indexBy } from "rambda";
import { LayoutProps } from "./standalone/tree-layout-utils";
import type { HierarchyPointNode, HierarchyNode } from "d3-hierarchy";
import { SyncService } from "frontend/services/syncService";
import { RW } from "shared/datamodel/replicache-wrapper/mutators";
import { ReadTransaction } from "@workcanvas/reflect";
import { canvasElementPrefix } from "shared/util/utils";
import { MindmapOrgChartNodeElement } from "shared/datamodel/schemas/mindmap-org-chart";
import { NodeData } from "./standalone/org-chart-skeleton";

type MinimalTree = Pick<OrgChartElement, "data" | "layout">;
type LayoutData = Pick<OrgChartElement, "layout">["layout"];
export type Relationship = "parent" | "child" | "sibling-after" | "sibling-before";

const alphabet = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
export const createNodeId = customAlphabet(alphabet, 10);

export const getItemView = (element: OrgChartElement | MindmapOrgChartNodeElement) =>
  element.type == "mindmapOrgChart" ? OrgChartViewType.NoProfilePicture : element.viewType ?? OrgChartViewType.Default;

export interface SmartNodeId {
  get fullElementId(): string;
  get rootId(): string;
  get nodeId(): string;
  isRoot: boolean;
}

export interface DragState {
  // mouse position on drag state (mouseEvent.screenX/Y)
  x0: number;
  y0: number;
  nodeIdUnderMouse: SmartNodeId;
  roots: SmartNodeId[];
  draggedNodes: string[];
  drop_targets: any[];
  stage: any;
  // current mouse x,y
  x: number;
  y: number;
  //
  dx: number;
  dy: number;
}

//-----------------------------------------------------------------------------
//#region Colors
const LIGHT_VARIANT: Record<string, string> = {
  "#081B2F": "#9098A1",
  "#E03E1A": "#EF9E8C",
  "#F5B53D": "#F8CF81",
  "#00B875": "#80DBBA",
  "#1973FF": "#8CB9FF",
  "#7549F5": "#BAA4FA",
  "#E9478B": "#F4A3C5",
  "#A5AAAD": "#C4C8CA",
  "#F38528": "#F7AE97",
  "#F0C630": "#FFD748",
  "#9FDA8B": "#B7E3A8",
  "#57CEF3": "#9AE2F8",
  "#C883FE": "#E3C1FE",
  "#FFA6BF": "#FFBFD1",
};

const LIGHTER_VARIANT: Record<string, string> = {
  "#081B2F": "#F4F7FA",
  "#E03E1A": "#FDF0ED",
  "#F5B53D": "#FEF8EC",
  "#00B875": "#E6F8F1",
  "#1973FF": "#E6F1FF",
  "#7549F5": "#F1EDFE",
  "#E9478B": "#FDEDF3",
  "#A5AAAD": "#F4F7FA",
  "#F38528": "#FEF4EC",
  "#F0C630": "#FFFBEB",
  "#9FDA8B": "#F3FAF0",
  "#57CEF3": "#EEFAFE",
  "#C883FE": "#FAF3FF",
  "#FFA6BF": "#FFF4F7",
};

export function lightenColor(color: string, amount = 0.4) {
  return LIGHT_VARIANT[color] ?? ligherBy(color, amount);
}

export function lightenColorMore(color: string) {
  return LIGHTER_VARIANT[color] ?? ligherBy(color, 0.8);
}

function ligherBy(color: string, f: number) {
  return RGBtoString(lightenColorBy(stringToRGB(color), f));
}

const colorRegex = /#(..)(..)(..)/i;
function stringToRGB(color: string): RGB {
  const match = colorRegex.exec(color);
  if (match) {
    const r = parseInt(match[1], 16),
      g = parseInt(match[2], 16),
      b = parseInt(match[3], 16);
    return [r, g, b];
  }
  return [0, 0, 0];
}

function RGBtoString([r, g, b]: RGB) {
  return "#" + r.toString(16).padStart(2, "0") + g.toString(16).padStart(2, "0") + b.toString(16).padStart(2, "0");
}

type RGB = [number, number, number];
// algorithm taken from the css-tricks website
function lightenColorBy([r, g, b]: RGB, f: number): RGB {
  const lowest = Math.min(r, g, b);
  const newLowest = Math.round(lowest + Math.min(255 - lowest, 255 * f));
  const increaseFraction = (newLowest - lowest) / (255 - lowest);
  if (lowest == r) {
    r = newLowest;
    g = Math.round(g + (255 - g) * increaseFraction);
    b = Math.round(b + (255 - b) * increaseFraction);
  } else if (lowest == g) {
    r = Math.round(r + (255 - r) * increaseFraction);
    g = newLowest;
    b = Math.round(b + (255 - b) * increaseFraction);
  } else {
    r = Math.round(r + (255 - r) * increaseFraction);
    g = Math.round(g + (255 - g) * increaseFraction);
    b = newLowest;
  }
  return [Math.floor(r), Math.floor(g), Math.floor(b)];
}
//#endregion
//-----------------------------------------------------------------------------
//#region Node id parsing and building
const nodeIdRegex = new RegExp(`${consts.CANVAS_ELEMENTS.ORG_CHART_NODE}-([^-]*)-([^-]*)`);
const rootIdRegex = new RegExp(`${consts.CANVAS_ELEMENTS.ORG_CHART}-([^-]*)`);

export function makeNodeId(rootId: string, nodeId?: string) {
  return nodeId
    ? `${consts.CANVAS_ELEMENTS.ORG_CHART_NODE}-${rootId}-${nodeId}`
    : `${consts.CANVAS_ELEMENTS.ORG_CHART}-${rootId}`;
}

export function isIdOfRoot(nodeId: string) {
  return nodeId.startsWith(consts.CANVAS_ELEMENTS.ORG_CHART);
}

const InvalidNodeId: SmartNodeId = {
  fullElementId: "",
  rootId: "",
  nodeId: "",
  isRoot: false,
};

export function parseNodeId(nodeId: string): SmartNodeId {
  const match = nodeIdRegex.exec(nodeId);
  if (match) {
    return {
      get fullElementId() {
        return consts.CANVAS_ELEMENTS.ORG_CHART + "-" + match[1];
      },
      get rootId() {
        return match[1];
      },
      get nodeId() {
        return match[2];
      },
      isRoot: false,
    };
  }

  const match2 = rootIdRegex.exec(nodeId);
  if (match2) {
    return {
      get fullElementId() {
        return nodeId;
      },
      get rootId() {
        return match2[1];
      },
      get nodeId() {
        return "root";
      },
      isRoot: true,
    };
  }
  return InvalidNodeId;
}
//#endregion

export const createNewIds = pipe(
  keys,
  map((id) => [id, createNodeId()] as [string, string]),
  fromPairs
);

// TODO: all these functions can be grouped to a 'info' object on hierarchy

type ChildParentMapping = Record<string, [string, number]>;

const buildChildParentMapping = (layout: OrgChartElement["layout"]) => {
  let mapping: ChildParentMapping = {};
  for (const key in layout) {
    if (layout[key].childrenIds) {
      layout[key].childrenIds.forEach((childId, index) => (mapping[childId] = [key, index]));
    }
  }
  return mapping;
};

const ancestors = (mapping: ChildParentMapping, id: string) => {
  const result = [];
  while (mapping[id]) {
    result.push(mapping[id][0]);
    id = mapping[id][0];
  }
  return result;
};

const disjointSets = <T,>(a: T[], b: T[]) => !any((item) => a.includes(item), b);

const computeHeight = (element: OrgChartElement, root = "root") => {
  let height: Record<string, number> = {};
  let getHeight = (id: string) => {
    if (height[id]) return height[id];
    if (element.layout[id].childrenIds.length == 0) {
      return (height[id] = 0);
    }
    let h: number = Math.max.apply(null, element.layout[id].childrenIds.map(getHeight)) + 1;
    return (height[id] = h);
  };
  getHeight(root);
  return height;
};

export const updatePhotoInNode = produceWithPatches((draft, nodeId, url) => {
  draft.data[nodeId][PHOTO_ID] = url;
});

export const updateFieldInNode = produceWithPatches((draft, nodeId, fieldId, value) => {
  draft.data[nodeId][fieldId] = value;
});

export const toggleCollapseNode = produceWithPatches((draft, nodeId) => {
  draft.layout[nodeId].collapsed = !draft.layout[nodeId].collapsed;
});

const removeNodeFromParent = (draft: Pick<OrgChartElement, "layout">, nodeId: string) => {
  for (const key in draft.layout) {
    let i = draft.layout[key].childrenIds?.indexOf(nodeId);
    if (i != undefined && i != -1) {
      draft.layout[key].childrenIds!.splice(i, 1);
      break;
    }
  }
};

//#region Adding new nodes

const addNewNode = (draft: MinimalTree, nodeId?: string) => {
  nodeId ??= createNodeId();
  draft.layout[nodeId] = { id: nodeId, collapsed: false, childrenIds: [] };
  draft.data[nodeId] = { id: nodeId };
  return nodeId;
};

export const findParent = (element: OrgChartElement, id: string) =>
  Object.keys(element.layout).find((key) => element.layout[key].childrenIds.includes(id));

const findParentAndSiblingNumber = (draft: MinimalTree, nodeId: string): [string | null, number] => {
  for (const key in draft.layout) {
    let i = draft.layout[key].childrenIds?.indexOf(nodeId);
    if (i != undefined && i != -1) {
      return [key, i];
    }
  }
  return [null, -1];
};

const attachNodeUnder = (draft: MinimalTree, nodeId: string, parentId: string, childIndex?: number) => {
  draft.layout[parentId].childrenIds ??= [];
  if (childIndex != undefined) {
    draft.layout[parentId].childrenIds!.splice(childIndex + 1, 0, nodeId);
  } else {
    draft.layout[parentId].childrenIds!.push(nodeId);
  }
};

// add node to orgchart
export const createAndInsertNode = (draft: MinimalTree, referenceNodeId: string, relation: Relationship) => {
  let nodeId = createNodeId();
  let index: number | undefined;
  let insertPoint: string | null = null;
  switch (relation) {
    case "child":
      insertPoint = referenceNodeId;
      break;
    case "sibling-after":
      [insertPoint, index] = findParentAndSiblingNumber(draft, referenceNodeId);
      break;
    case "sibling-before":
      [insertPoint, index] = findParentAndSiblingNumber(draft, referenceNodeId);
      index--;
      break;
    case "parent":
      throw new Error('Relation "parent" not implemented');
  }
  attachNodeUnder(draft, nodeId, insertPoint!, index);
  draft.layout[nodeId] = { id: nodeId, collapsed: false, childrenIds: [] };
  draft.data[nodeId] = {
    id: nodeId,
    [DRAW_PROPS_ID]: draft.data[referenceNodeId][DRAW_PROPS_ID],
  };
};

//#endregion

export const deleteChildFromOrgChart = produceWithPatches((draft: OrgChartElement, childId) => {
  delete draft.data[childId];
  delete draft.layout[childId];
  removeNodeFromParent(draft, childId);
});

export const deleteNodes = produceWithPatches((draft: OrgChartElement, nodeIds: string[] | null) => {
  if (!nodeIds) return;
  const reverseMap = buildChildParentMapping(draft.layout);
  for (const nodeId of nodeIds) {
    delete draft.data[nodeId];
    delete draft.layout[nodeId];
    const [parent, _] = reverseMap[nodeId];
    if (draft.layout[parent]) {
      draft.layout[parent].childrenIds = draft.layout[parent].childrenIds!.filter((id) => id != nodeId);
    }
  }
});

export const moveNodesToLeftOfAnother = produceWithPatches((draft: OrgChartElement, ids, targetId, targetParentId) => {
  if (!draft.layout[targetParentId].childrenIds) {
    return; // if the parent has no children, we can't move to the left of anything
  }
  for (const nodeId of ids) {
    removeNodeFromParent(draft, nodeId);
    let i = draft.layout[targetParentId].childrenIds!.indexOf(targetId);
    draft.layout[targetParentId].childrenIds!.splice(i, 0, nodeId);
  }
});

export const moveNodesToRightOfAnother = produceWithPatches((draft: OrgChartElement, ids, targetId, targetParentId) => {
  if (!draft.layout[targetParentId].childrenIds) {
    return; // if the parent has no children, we can't move to the right of anything
  }
  for (let nodeId of ids) {
    removeNodeFromParent(draft, nodeId);
    let i = draft.layout[targetParentId].childrenIds!.indexOf(targetId);
    draft.layout[targetParentId].childrenIds!.splice(i + 1, 0, nodeId);
  }
});

export const moveNodesUnderAnother = produceWithPatches((draft: OrgChartElement, ids, targetId) => {
  for (const id of ids) {
    removeNodeFromParent(draft, id);
    attachNodeUnder(draft, id, targetId!);
  }
});

/**
 * Filter the 'ids' list, keep only nodes who don't have ancestors in the list.
 * @param element
 * @param ids
 */
const filterForSubtreeRoots = (element: MinimalTree, ids: string[]) => {
  const revMapping = buildChildParentMapping(element.layout);
  return ids.filter((id) => disjointSets(ancestors(revMapping, id), ids));
};

export const copyNodes = (element: MinimalTree, roots: string[]) => {
  roots = filterForSubtreeRoots(element, roots);

  let allInvolved = new Set<string>();
  function addRec(id: string) {
    if (allInvolved.has(id)) return;
    allInvolved.add(id);
    element.layout[id].childrenIds.forEach(addRec);
  }
  roots.forEach(addRec);

  const topick = [...allInvolved];

  return {
    data: pick(topick, element.data),
    layout: pick(topick, element.layout),
    roots,
  };
};

export const copyPasteInPlace = produceWithPatches(
  (draft: OrgChartElement, nodeIds: string[], copySubtrees = false) => {
    const mapping = buildChildParentMapping(draft.layout);
    // when copying several nodes, there's the option node X and its ancestor Y
    // are both in the list. In that case X is copied twice (if I simply loop on nodeIds)
    // my solution: keep a cache of nodes that were copied, to avoid duplicates.
    // Also, loop on nodeIds by order of height (closest to root first) so we
    // go over the ancestors first and then the leaves.
    // of course, only necessary if we're recursing into subtrees.
    let visited = new Set<string>();

    // bug: endless recursion when copying tree into itself
    function copyNode(rootId: string, parent: string, recurse: boolean) {
      if (visited.has(rootId)) return;
      visited.add(rootId);
      const newId = addNewNode(draft);
      attachNodeUnder(draft, newId, parent);
      draft.data[newId] = { ...draft.data[rootId], id: newId };
      if (recurse) {
        draft.layout[rootId].childrenIds.forEach((childId) => copyNode(childId, newId, recurse));
      }
    }
    if (copySubtrees) {
      let height = computeHeight(draft);
      nodeIds.sort((a, b) => height[b] - height[a]);
    }

    for (const id of nodeIds) {
      copyNode(id, mapping[id][0], copySubtrees);
    }
  }
);

export const copyPasteUnderNewParent = produceWithPatches(
  (draft: OrgChartElement, nodeIds: string[], newParent: string, copySubtrees = false) => {
    if (!draft.layout[newParent]) return;

    // when copying several nodes, there's the option node X and its ancestor Y
    // are both in the list. In that case X is copied twice (if I simply loop on nodeIds)
    // my solution: keep a cache of nodes that were copied, to avoid duplicates.
    // Also, loop on nodeIds by order of height (closest to root first) so we
    // go over the ancestors first and then the leaves.
    // of course, only necessary if we're recursing into subtrees.
    let visited = new Set<string>();

    function copyNode(rootId: string, parent: string, recurse: boolean) {
      if (visited.has(rootId)) return;
      visited.add(rootId);
      const newId = addNewNode(draft);
      attachNodeUnder(draft, newId, parent);
      draft.data[newId] = { ...draft.data[rootId], id: newId };
      if (recurse) {
        draft.layout[rootId].childrenIds.forEach((childId) => copyNode(childId, newId, recurse));
      }
    }
    if (copySubtrees) {
      let height = computeHeight(draft);
      nodeIds.sort((a, b) => height[b] - height[a]);
    }
    nodeIds.forEach((id) => copyNode(id, newParent, copySubtrees));
  }
);

//-------------------------------
/**
 * Walk a n-ary tree (as defined by d3 library) in breadth-first order.
 * Can ignore some subtrees according to a predicate.
 * This generator will yield only the nodes that aren't rejected and their children, making the
 * tree look like a new tree with the rejected nodes removed.
 * @param root - the root of the tree
 * @param ignoreIf - return true if the node should be ignored
 */
function* walkTreeBfs<T extends HierarchyNode<any>>(root: T, ignoreIf: (node: T) => boolean): Generator<T> {
  function* actualWalk(n: T): Generator<T> {
    if (n.children?.length && n.children.some(ignoreIf)) {
      n = { ...n, children: reject(ignoreIf, n.children!) };
      if (n.children!.length == 0) delete n.children;
    }
    yield n;
    if (n.children) {
      for (const child of n.children) {
        yield* actualWalk(child);
      }
    }
  }
  // we check the root separately, since all other nodes in the children list of some other node,
  // and they will be checked there.
  if (ignoreIf(root)) return;
  yield* actualWalk(root);
}

export function calculateDragDropTargets(
  tree: HierarchyPointNode<any>,
  orientation: OrgChartOrientation,
  width: number,
  height: number
) {
  const isHorizontal = LayoutProps[orientation].isHorizontal;
  let w = isHorizontal ? height : width;
  let h = isHorizontal ? width : height;
  const c = LayoutProps[orientation].convert;

  let connections: Array<{
    x: number;
    y: number;
    dir: Relationship;
    node: any;
  }> = [];
  const addDropTarget = (x: number, y: number, node: any, dir: Relationship) => {
    [x, y] = c(x, y);
    connections.push({
      x,
      y,
      dir,
      node,
    });
  };

  //TODO: don't have to use walkTreeBfs, can just use tree.descendants()
  for (const node of walkTreeBfs(tree, () => false)) {
    addDropTarget(node.x, node.y + h * 0.55, node, "child");
    if (node.parent) {
      addDropTarget(node.x + 0.55 * w, node.y, node, "sibling-after");
      addDropTarget(node.x - 0.55 * w, node.y, node, "sibling-before");
    }
  }
  return connections;
}

export function collectDescendants(layout: LayoutData, rootId: string, includeRoot = false): string[] {
  const desc = layout[rootId].childrenIds.flatMap((childId) => collectDescendants(layout, childId, true));
  return includeRoot ? desc.concat([rootId]) : desc;
}

//-------------------------------
export function countOrgChartNodesInReflect(syncService: SyncService<RW>) {
  return syncService.getReplicache()!.query(async function (tx: ReadTransaction) {
    const allOrgChartsInCanvas = tx.scan({ prefix: canvasElementPrefix + consts.CANVAS_ELEMENTS.ORG_CHART });
    let total = 0;
    for await (const element of allOrgChartsInCanvas) {
      const orgChart = element as OrgChartElement;
      if (orgChart.hidden) continue;
      total += numberOfNodes(orgChart);
    }
    return total;
  });
}
//-------------------------------
export function numberOfNodes(orgChart: OrgChartElement) {
  return Object.keys(orgChart.layout).length;
}
//-------------------------------
export function getAncestors(nodes: NodeData[], ids: string[]) {
  let ancestors: Record<string, boolean> = {};
  let nodesById = indexBy("id", nodes);
  for (const id of ids) {
    let cur: string | undefined = id;
    while (cur && !ancestors[cur]) {
      ancestors[cur] = true;
      cur = nodesById[cur]?.parentId;
    }
  }
  return ancestors;
}
//-------------------------------
